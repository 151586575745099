@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"



























































































































































$font-size-custom-info-text: 24px
.score-card
  .info-2.--x-large:not(:first-child)
    border-left: 1px solid transparentize($color-ink-lighter, .5)

  .info-2:first-child
    margin-left: 0 !important
    padding-left: 0 !important

  .info__label
    font-size: $font-size-m

  .info-card__footer
    .info__text
      font-size: $font-size-custom-info-text
    .info__label
      font-size: $font-size-s

