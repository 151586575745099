@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"


































































































































.drawer-questions
  background: $color-ice

  &__header
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    background: $color-white

    &__title
      width: 100%
      display: flex
      justify-content: space-between
      padding: $size-xs $size-s
      border-bottom: 1px solid $color-ink-lightest

      +mq-m--mf
        padding: $size-m $size-l

      &__text
        font-size: $font-size-heading-4

  &__content
    padding: $size-s

    +mq-s--mf
      padding: $size-m $size-m
