@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"

































































































































































































































































































$vertical-bar-letter-spacing: 0
$custom-font-size-s: 9px
$custom-font-size-xs: 8px
$color-highlight: #438DE4

.result-percentile
  padding: 16px
  box-sizing: border-box
  display: grid
  grid-template-columns: 1fr 1.1fr
  grid-template-areas: "chart ." "chart text" "chart ." "chart info" "chart ."
  grid-gap: 24px 80px

  &.--medium-size
    grid-template-areas: "text" "chart"
    grid-gap: 0

    .result-percentile__chart-legend
      margin-top: 30px
      justify-content: center

    .result-percentile__text
      text-align: center
      background: transparent
      width: 100%
      padding: 8px 0

      .--highlight
        font-size: $font-size-m
        font-weight: $font-weight-semi-bold
        color: $color-highlight

    .vertical-bar-chart
      &__bars
        +mq-l
          grid-template-columns: repeat(5, 48px) !important
          column-gap: 16px !important
          padding: 0 12px

        +mq(860px)
          column-gap: 12px !important
          padding: 0

  .vertical-bar-chart__bars
    small
      letter-spacing: $vertical-bar-letter-spacing

    +mq-s
      small
        font-size: $custom-font-size-s
        letter-spacing: .1px

    +mq-xs
      small
        font-size: $custom-font-size-xs

  +mq-l
    grid-template-columns: 100%
    grid-template-areas: "text" "chart" "info"
    padding: 8px

  +mq-s
    padding: 0
    gap: 32px

  &-wrapper
    width: 100%

  &__chart
    grid-area: chart

    &-legend
      display: flex

      .sas-chart-legend ~ .sas-chart-legend
        margin-left: 48px

      +mq-s
        display: block

        .sas-chart-legend ~ .sas-chart-legend
          margin-left: 0
          margin-top: 8px

  &__info
    grid-area: info

  &__text
    display: block
    box-sizing: border-box
    background: transparentize($color-ink-lighter, .8)
    padding: 8px 16px
    font-size: 16px
    font-weight: 600
    width: 77%
    border-radius: 8px
    color: $color-ink-light
    grid-area: text
    +mq-l
      width: 100%
    +mq-s
      font-size: 14px

  &__loading
    width: 100%
    +flex-center
    padding: 80px
    box-sizing: border-box
