@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"

















































































































































































































































































































































































$question-order-color: #1E2124

.assessment-questions-table
  padding: $size-s 0

  +mq-m--mf
    padding: $size-l 0

  &__questions-amount
    margin-top: $size-xs
    font-size: $font-size-heading-6-small
    line-height: 24px
    color: $color-ink-light

  ::v-deep &__filters
    margin-top: $size-m
    display: flex
    flex-flow: column

    +mq-s--mf
      flex-flow: row

    &__lectures
      margin-top: $size-xs

    .filter-label
      font-size: $font-size-xs
      color: $color-ink-light
      margin: $size-xxs

      +mq-m--mf
        font-size: $font-size-s

    .adaptive-selector-dropdown__handle
      min-width: 160px

      &__caret
        margin-left: auto

  &__card-list
    margin-top: $size-m

  .sas-table-container
    margin-top: $size-m

    .data-table__column.question-status .sas-table__header__field
      justify-content: center

    .question
      &__order
        font-weight: 500
        font-size: $font-size-heading-6
        line-height: 24px
        color: $question-order-color

      &__description
        max-width: 100px
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        width: 200px
        font-size: $font-size-s
        line-height: 20px
        color: $color-ink-light

        +mq-m--mf
          max-width: 200px
