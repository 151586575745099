@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"















































































































.info-card

  &.--limited
    +mq-s--mf
      max-width: 300px

  &--horizontal
    .info-card__header
      display: none

    &__title
      align-self: center
      margin-left: $size-xxs

.engagement-chart__percentage__label
  display: inline
