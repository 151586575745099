@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"






































































































































































































































































































































.averages-performance
  width: 100%

  &__header

    &__title
      margin-bottom: $size-s

    &__buttons
      display: flex
      flex-wrap: wrap

      .sas-button
        text-transform: capitalize
        margin-right: $size-s

      .--selected
        color: $color-ink
        background: rgba(92, 155, 214, 0.15)

    &__loader
      display: flex
      flex-wrap:  wrap

      .skeleton-buttons
        width: 70px !important
        height: 28px !important
        margin-right: $size-s

        +mq-m--mf
          width: 90px !important
          height: 38px !important
          margin-right: $size-s

  &__content
    display: flex
    justify-content: flex-start
    align-content: center
    width: 100%
    margin-top: $size-l
    border-bottom: 1px solid $color-ink-lightest

    &__average
      display: flex
      flex-direction: column
      justify-content: flex-start
      align-content: center
      width: 100%
      padding: $size-xs 0
      margin-bottom: $size-s

      +mq-s--mf
        padding: $size-m 0

      +mq-l--mf
        flex-direction: row

      &:first-child
        border-right: 1px solid $color-ink-lightest
        padding-right: $size-xs

        +mq-s--mf
          padding-right: $size-m

        +mq-l--mf
          padding-right: 0

      &__grade
        display: flex
        justify-content: center
        align-items: flex-start
        font-size: $font-size-heading-4-small
        font-weight: $font-weight-medium
        color: $color-ink

        +mq-s--mf
          font-size: $font-size-heading-2
          font-weight: $font-weight-bold

        +mq-l--mf
          padding-right: $size-xxl
          font-size: $size-xl

        &__icon
          width: 44px
          height: 44px
          color: $color-primary

          +mq-s--mf
            width: 56px
            height: 56px

          +mq-l--mf
            margin: 0 $size-m

        .average
          display: flex
          flex-direction: column

          &__label
            color: $color-ink-light
            font-size: $font-size-s

            +mq-s--mf
              margin-bottom: $size-xs

            +mq-l--mf
              font-size: $font-size-heading-5
              margin-bottom: $size-s

      &__global
        display: flex
        flex-direction: row
        justify-content: center
        align-items: baseline
        font-size: $font-size-s
        font-weight: $font-weight-bold
        color: $color-ink
        margin-top: $size-s

        +mq-s--mf
          align-items: center
          flex-direction: column
          font-size: $font-size-heading-3

        +mq-m--mf

        +mq-l--mf
          align-items: flex-start

        &__label
          color: $color-ink-light
          font-size: $font-size-xs
          margin-bottom: $size-xxs
          margin-right: $size-xs

          +mq-s--mf
              font-size: $font-size-s

      &__loader
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        width: 50%

        +mq-l--mf
          padding: $size-xs 0 $size-xs 0
          flex-direction: row

        .group
          display: flex
          justify-content: center
          align-items: center

        .skeleton-icon.sas-skeleton-loader
          margin-left: 20px
          height: 40px !important
          width: 40px !important

          +mq-l--mf
            margin-left: 20px
            height: 56px !important
            width: 56px !important

        .average-info
          margin: $size-s $size-xxs $size-xxs $size-xxs

          +mq-m--mf
            margin: $size-s

          &.--irt
            .sas-skeleton-loader
              width: 70px !important
              height: 18px !important
              margin-right: 16px

              +mq-s--mf
                width: 110px !important
                height: 32px !important
                margin-right: 16px

              +mq-l--mf
                width: 210px !important
                height: 40px !important
                margin-right: 16px

              &:first-child
                margin-bottom: $size-xxs
                height: 10px !important

                +mq-s--mf
                  height: 14px !important

                +mq-l--mf
                  height: 16px !important

          &.--classic
            display: flex
            margin-top: $size-m
            margin-bottom: $size-m

            +mq-s--mf
              flex-direction: column

            .sas-skeleton-loader
              width: 60px !important
              height: 12px !important

              +mq-s--mf
                width: 60px !important
                height: $size-l !important
                margin-right: $size-s

              +mq-l--mf
                width: 110px !important
                height: 40px !important

              &:first-child
                margin-bottom: $size-xxs
                margin-right: $size-xxs
                height: 12px !important

                +mq-s--mf
                  height: $size-xs!important

                +mq-l--mf
                  height: $size-s !important

  &__footer
    display: flex
    flex-wrap: wrap
    padding: $size-xxs

    +mq-s--mf
      padding: $size-m 0 $size-s

    &__info
      display: flex
      font-weight: $font-weight-medium
      width: 100%

      +mq-m--mf
        width: auto
        min-width: 120px
        margin-right: $size-m

      &__details
        display: flex
        flex-direction: column
        font-weight: $font-weight-medium
        color: $color-ink
        font-size: $font-size-m
        width: 100%

        +mq-s--mf
          margin-right: $size-m
          font-size: $font-size-heading-5
          min-width: 200px

        label
          font-size: $font-size-xs
          font-weight: $font-weight-medium
          color: $color-ink-light

          +mq-s--mf
            font-size: $font-size-s

    &__loader
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      margin-right: $size-s
      margin-top: $size-xs
      width: 50%

      +mq-s--mf
        width: auto

      .sas-skeleton-loader
        height: 12px !important
        width: 120px !important
        margin-bottom: $size-xxs

  .session-average
    display: flex
    width: 100%

