@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"



















.table
  font-family: 'Avenir', Helvetica, Arial, sans-serif
  width: 100%
  border-collapse: separate
  border-spacing: 0
  border-radius: 8px
  overflow: hidden

  tr:last-child td
    border-bottom: none

  thead
    background: transparentize($color-ink, 0.85)
    text-align: left
    font-size: 13px
    color: transparentize($color-ink, .3)

    th
      font-weight: 500
      padding: 12px 24px
      border-bottom: 1px solid transparentize($color-ink-lighter, 0.4)

  tbody
    tr
      height: 56px
      color: transparentize($color-ink, .2)

      td
        padding: 16px 24px
        font-size: 14px
        border-bottom: 1px solid transparentize($color-ink-lighter, 0.6)

      &:nth-child(even)
        background: transparentize($color-ink-lightest, 0.8)

      &:nth-child(odd)
        background: transparentize($color-ink-lightest, 0.4)

