@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"




















































































































$font-size-custom-text: 28px

.info-card
  display: grid
  grid-template-rows: repeat(3, max-content)
  align-content: space-between

  &__header,
  &__body,
  &__footer
    box-sizing: border-box

  &__header
    +flex-space-between
    color: $color-ink-light
    padding: 24px 32px

    +mq-l
      padding: 16px 24px

  &__title
    line-height: 1
    font-weight: $font-weight-semi-bold
    font-size: $font-size-heading-3

  &__body
    display: flex
    padding: 48px 32px
    justify-content: flex-start

    +mq-l
      padding: 24px

    +mq-s
      padding: 16px

  &__footer
    align-self: flex-end
    display: flex
    align-items: center
    justify-content: flex-start
    padding: 16px 32px

    +mq-l
      padding: 12px 24px

    &:not(:empty)
      border-top: 1px solid transparentize($color-ink-lighter, .7)

    .info-2 ~ .info-2
      margin-left: 40px

  .info-card__body .info__content
    .info__text
      label
        font-size: $size-xl

  .info-card__footer .info__content
    .info__label
      font-size: $font-size-s
    .info__text
      font-size: $font-size-custom-text

