@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"







































































































































.exam-results__performance-card
  padding: 0

  .performance-card__loading
    display: flex
    align-items: center
    justify-content: center
    height: 384px

  .performance-card__body
    display: flex
    justify-content: space-between
    padding: 16px
    box-sizing: border-box

  .performance-card__left
    width: 416px

  .performance-card__toggle
    margin-top: 40px
    margin-bottom: 16px

    &-label
      text-align: left

  .performance-card__subcategories
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr))

