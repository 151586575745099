@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"








































































































































































.info-2
  display: flex
  align-items: center
  box-sizing: border-box

  &.--loading
    width: 100%

  &.--light
    .info__label
      color: $color-ink-light
  &.--dark
    .info__label
      color: rgba(255, 255, 255, .75)

.info

  &__content.--loading
    width: 100%

  // Icon
  &__icon
    flex-shrink: 0

    // Sizes
    &.--x-small
      margin-right: 8px
      svg
        width: 20px
        height: 20px

    &.--small
      margin-right: 8px
      svg
        width: 24px
        height: 24px

    &.--medium
      margin-right: 8px
      svg
        width: 32px
        height: 32px

    &.--large
      margin-right: 12px
      svg
        width: 48px
        height: 48px

    &.--x-large
      margin-right: 16px
      +mq-l
        margin-right: 12px
      +mq-m
        margin-right: 8px

      svg
        width: 64px
        height: 64px

        +mq-l
          width: 48px
          height: 48px
        +mq-m
          width: 40px
          height: 40px

  &__label
    font-weight: 500
    margin-bottom: 5px
    margin-left: $size-xs

  h1, h2, h3, h4, h5, h6,
  span, p, label,
  &__text,
  &__label
    line-height: 1
    margin-left: $size-xs

