@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"









































































.progress-bar
  position: relative

  &__background,
  &__foreground
    transition: all 800ms cubic-bezier(0.86, 0, 0.07, 1)

  &__background
    fill: $color-ink-lightest

