@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"

































































































































































































































































































































































.data-table
  width: 100%
  border-collapse: separate
  border-spacing: 0
  position: relative
  padding-bottom: 5px
  transition: all 200ms ease-in-out
  box-sizing: border-box

  // ---------------------------------------------
  // Densities
  // ---------------------------------------------
  $size-x-large: 48px
  $size-large: 32px
  $size-medium: 24px
  $size-small: 16px
  $size-x-small: 8px
  $size-xx-small: 4px

  &.--density-x-large
    td
      padding: $size-large $size-x-large
      font-size: 16px
    th
      padding: $size-x-small $size-x-large

  &.--density-large
    td
      padding: $size-medium $size-large
      font-size: 16px
    th
      padding: $size-x-small $size-large

  &.--density-medium
    td
      padding: $size-small $size-medium
    th
      padding: $size-x-small $size-medium

  &.--density-small
    td, th
      padding: $size-x-small $size-small

  &.--density-x-small
    td, th
      padding: $size-x-small $size-xx-small

  // --------------------------------------------------
  // Loading State
  // --------------------------------------------------
  &.--loading
    pointer-events: none

  // --------------------------------------------------
  // Vertical Lines
  // --------------------------------------------------
  &.--vertical-lines
    td:not(:last-child),
    th:not(:last-child)
      border-right: 1px solid transparentize($color-ink-lighter, 0.5)

  // --------------------------------------------------
  // Freeze First Column
  // --------------------------------------------------
  &.--freeze-first-column
    td:first-child,
    th:first-child
      left: 0
      position: sticky

      &::after
        content: ""
        position: absolute
        top: 0
        right: 0
        height: 106%
        width: 7px
        box-shadow: 3px 0 6px -3px rgba(0,0,0,.25)

    td:first-child
      z-index: 1

    th:first-child
      z-index: 30

    td:nth-child(2)
      margin-left: 8px

  // --------------------------------------------------
  // Footer Row
  // --------------------------------------------------
  &__footer
    td
      background: tint-color($color-ink, 85%) !important
      border-top: 1px solid transparentize($color-ink-lighter, 0.4)
      vertical-align: middle
      color: $color-ink-light
      box-sizing: border-box
      box-shadow: none
      position: sticky
      bottom: 0

      span
        font-size: 13px
        font-weight: 500

      &:first-child span
        width: 100%
        font-weight: 600
        display: block
        text-align: right !important

  // --------------------------------------------------
  // Header
  // --------------------------------------------------
  &__header
    text-align: left

    &__full
      width: 100%

    &__field
      display: flex
      align-items: center
      justify-content: flex-start

      &__icon
        margin-left: 4px
        opacity: .75
        flex-shrink: 0

    th
      background: tint-color($color-ink, 85%)
      position: sticky
      z-index: 8
      top: 0
      padding-right: 4px !important
      border-bottom: 1px solid transparentize($color-ink-lighter, 0.5)
      font-size: 13px
      vertical-align: middle
      color: $color-ink-light
      box-sizing: border-box

      small
        font-weight: 600
        user-select: none
        font-size: $font-size-s

      &.--sortable
        cursor: pointer

        &:hover
          background: tint-color($color-ink, 75%)
          color: $color-ink
          transition: all 200ms ease-in-out

      &:first-child
        text-align: left !important

  // --------------------------------------------------
  // Actions
  // --------------------------------------------------
  &__actions
    padding-left: 0 !important
    padding-right: 8px

  &.--selectable
    td:nth-child(2)
      font-weight: 600

  // --------------------------------------------------
  // Row
  // --------------------------------------------------
  tr
    transition: all 200ms ease-in-out

    &:focus
      outline: 0

    &:hover td
      background-color: tint-color($color-ink-lightest, 70%)

    &:last-child td
      border-bottom: none

    // Cell
    td
      border-bottom: 1px solid transparentize($color-ink-lighter, 0.6)
      box-sizing: border-box
      background: white
      font-size: 14px

      &:first-child
        font-weight: 600
        text-align: left !important

    // Active
    $row-active-border: 2px solid $color-primary

    &.--active
      td
        background-color: tint-color($color-primary, 90%) !important
        border-bottom: $row-active-border
        border-top: $row-active-border

        &:first-child
          border-left: $row-active-border

        &:last-child
          border-right: $row-active-border

      &:hover td
        background-color: tint-color($color-primary, 80%) !important

  // --------------------------------------------------
  // Striped Row
  // --------------------------------------------------
  &.--striped tr:nth-child(even)
    td
      background: tint-color($color-ink-lightest, 85%)

    &:hover td
      background-color: tint-color($color-ink-lightest, 70%)

