@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"



































































































































































































.hits-board

  &__warning
    color: #BF3434
    padding: 8px 24px
    border-bottom: 1px solid $color-ink-lightest

    .info__icon
      background: $color_danger
      box-sizing: border-box
      padding: 8px
      border-radius: 24px

    .info__label
      color: #BF3434 !important

  &__hits
    padding: 24px
    box-sizing: border-box
    width: 80%

    +mq-m
      width: 100%

    &--skeleton
      margin-top: 32px

  &__legend
    margin-top: 24px
    display: flex
    justify-content: space-between

    &--left
      color: $color-ink-light

  &__action
    width: 40%
    display: flex
    flex-direction: column

    +mq-l
      width: 50%

    +mq-m
      display: none

    &-button
      margin-top: 24px
      width: 204px

      &--skeleton
        margin-top: 24px

