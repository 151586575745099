@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"









































.questions-banner
  cursor: pointer

  &__box
    padding: 0 0 $size-m 0 !important
    background-image: url("~@/assets/curve-question-banner.svg")  !important
    background-repeat: no-repeat !important

    display: flex
    background-size: contain
    flex-direction: column
    align-items: center
    justify-content: center

    +mq-m--mf
      background-size: 100% !important

    +mq-l--mf
      justify-content: space-around
      flex-direction: column

    &__img
      padding-top: $size-m
      width: 100%

    &__info
      width: 100%
      padding: 0 $size-m
      justify-content: center

      +mq-l--mf
        padding: 0 $size-s
        width: 100%

      &__title
        text-align: left
        font-size: $font-size-heading-4
        font-weight: 500
        color: $color-main
        margin-bottom: $size-xs

        +mq-l--mf
          font-size: $font-size-heading-4
          color: $color-main

      &__description
        display: flex
        justify-content: flex-start
        color: $color-ink
        font-size: $font-size-s

        +mq-l--mf
          color: $color-ink
          font-size: $font-size-m
          justify-content: flex-start

        &__button
          color: $color-main

          +mq-l--mf
            color: $color-main

        &__text
          width: calc(100% - 40px)

          +mq-l--mf
            margin-right: $size-l

        &__icon
          width: $size-m
          color: $color-main

          +mq-m--mf
            width: 36px

          +mq-l--mf
            width: 42px
            color: $color-white
