@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"














































































































































































































































































































































































































































$borderStyle: 1px solid transparentize($color-ink-lightest, 0.5)
$color-black-low-opacity : rgba(0, 0, 0, 0.15)
$color-ink-lighter-custom: #b1afaf

.sas-table-container
  .box-sas-table
    position: relative

    .container-loading
      position: absolute
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      background: #0000001f
      z-index: 12

      .sas-box
        box-shadow: 0px 2px 6px 4px $color-ink-lighter-custom
        display: flex
        flex-flow: column
        justify-content: space-between
        min-height: 110px
        align-items: center
        margin-top: 40px

  .sas-table
    font-family: $font-family-primary
    width: 100%
    border-collapse: separate
    border-spacing: 0
    position: relative
    transition: all 200ms ease-in-out
    box-sizing: border-box

    &.always-show-head
      thead
        display: table-header-group

        +mq-l--mf
          display: table-header-group

      .data-table__body__checkbox
          display: table-cell

    thead
      display: none

      +mq-l--mf
        display: table-header-group

      th
        padding: $size-xs

    td,
    th
      border-bottom: $borderStyle

    td
      box-sizing: border-box
      padding: $size-xs
      font-size: $font-size-s
      transition: all $speed-fast ease-in-out

      &.data-table__body__checkbox
        display: none

        +mq-l--mf
          display: table-cell

      &.data-table__body__index
        padding-left: $size-m
        width: 10px

    th
      padding: 16px 8px 16px 16px
      text-align: left

    tr
      transition: all $speed-fast ease-in-out

      &:focus
        outline: 0

      &:last-child
        td
          border-bottom: none

    &__header
      &__field
        height: $size-s
        +flex-center-start

        &__icon
          margin-left: $size-xxs
          transition: all $speed-fast
          flex-shrink: 0

          &.--desc
            transform: scaleY(-1)
    thead,
    tbody,
    tfoot
      tr:last-child
        th
          border-bottom: none

      .data-table__column
        &.xs
          display: table-cell

        &.xs-only
          display: table-cell
          +mq-s--mf
            display: none

        &.sm
          display: none
          +mq-s--mf
            display: table-cell

        &.md
          display: none
          +mq-m--mf
            display: table-cell

        &.lg
          display: none
          +mq-l--mf
            display: table-cell

    thead
      text-align: left

      tr
        &:first-child
          th
            &:first-child
              border-top-left-radius: $size-xs

            &:last-child
              border-top-right-radius: $size-xs

      th
        background: transparentize($color-ink-lightest, 0.75)
        box-sizing: border-box
        color: $color-ink-light
        font-size: 11px
        font-weight: 400
        text-transform: uppercase
        vertical-align: middle
        letter-spacing: 1.3px

        &.--sortable
          cursor: pointer

        small
          font-weight: $font-weight-semi-bold
          user-select: none
    tbody
      tr.additional-info
        td
          padding-top: 25px

    tfoot
      tr:first-child
        td,
        th
          border-top: $borderStyle

    &.--vertical-lines
      td:not(:last-child),
      th:not(:last-child)
        border-right: $borderStyle

  .sas-pagination__container
    display: none

    +mq-l--mf
      display: flex

  .show-more-pagination
    display: flex
    background: $color-white
    border: 1px solid $color-ink-lightest
    box-shadow: $shadow-s $color-black-low-opacity
    border-radius: $border-radius-m
    margin: $size-s auto
    color: $color-ink-light

    &:hover
      color: $color-white
      background: $color-ink-light

    +mq-l--mf
      display: none

