@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"


















































































.empty-state
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  box-sizing: border-box
  text-align: center

  &__image
    margin-bottom: 24px

  &__icon
    margin-bottom: 24px

  &__title
    color: $color-ink

  &__description
    margin-top: 4px
    color: $color-ink-light

  &__actions
    margin-top: 24px

