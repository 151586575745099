@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"































































































































































.exam-results__performance
  margin-top: 48px

  &-categories
    display: flex
    align-items: center
    justify-content: center

    .performance-categories

      &__pagination
        padding: 0

      &__badge
        border-top-left-radius: 8px
        border-top-right-radius: 8px
        padding: 12px 16px

        +mq-m

          h5
            font-size: 13px

  &--loading
    margin: 24px 0px

  .VueCarousel
    width: 90%

    &-dot
      margin-top: 0px !important

    &-dot-container
      margin-top: 0px !important
      margin-bottom: 16px

    &-slide
      flex-basis: unset
      width: 50%

      &:nth-child(odd)
        padding: 24px 2.5% 8px 2.5%

      &:nth-child(even)
        padding: 24px 2.5% 8px 2.5%

      +mq-s
        width: 100%
        padding: 24px 7.5% 8px 7.5% !important

