@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"



























































































.performance-category

  &__title
    height: 48px
    font-weight: $font-weight-semi-bold

  &__badge
    font-size: 14px
    font-weight: 600
    width: fit-content
    color: $color-ink-light
    padding: 4px 16px
    box-sizing: border-box
    border: 1px solid $color-ink-lighter
    border-radius: 8px
    margin: 24px 0px 0px 0px

  &__results
    display: flex
    align-items: center
    margin-top: 24px
    justify-content: space-between

    +mq-l
      flex-direction: column

  &__percentage
    font-size: 14px
    max-width: 72px
    color: $color-ink-light

  &__general-result
    padding: 8px 16px
    box-sizing: border-box
    border-radius: 8px
    background: $color-ink-lightest
    margin-right: 24px

    .info-right__text--custom-tag
      font-weight: $font-weight-semi-bold

    +mq-l
      margin-top: 16px
      width: 100%
      margin-right: unset

