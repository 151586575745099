@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"



















































































































































































































































































































































































































































































.question-details-drawer
  z-index: 200

.drawer-details-questions

  &__header
    display: flex
    flex-direction: column
    align-items: flex-start
    background: $color-white
    justify-content: flex-start

    &__title
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center
      padding: $size-xs $size-s
      border-bottom: 1px solid $color-ink-lightest

      +mq-m--mf
        padding: $size-m $size-l

      &__text
        font-size: $font-size-heading-4

      &__actions
        display: flex
        flex-direction: column
        align-items: center

        +mq-m--mf
          flex-direction: row

        &__status
          margin-right: $size-xs

          ::v-deep .sas-badge__text
            display: flex
            justify-content: center
            align-items: center

            .feather
              width: $font-size-m
              margin-right: $size-xxs

  &__info
    background: $color-ice
    border-top: 1px solid $color-ink-lightest
    border-bottom: 1px solid $color-ink-lightest
    padding: $size-s
    display: flex
    flex-direction: column

    +mq-m--mf
      padding: $size-m $size-l
      flex-direction: row

    &__details
      display: flex
      flex-direction: column
      margin-right: $size-xl

      +mq-m--mf
        margin-bottom: 0

      &:first-child
        margin-bottom: $size-s

      &__text
        text-transform: uppercase
        font-size: $font-size-xs
        color: $color-ink-light

      &__value
        color: $color-ink
        font-size: $font-size-s

        +mq-m--mf
          font-size: $font-size-m

  &__content
    display: flex
    flex-direction: column
    width: 100%
    background: $color-white

    &__player
      ::v-deep iframe
        width: 100% !important
        height: 350px
        border: none
        padding: $size-s 0
        box-sizing: border-box

      ::v-deep .skeleton-badge

        &--video
          margin: 0 auto
          height: 350px

      .alternative
        padding: $size-s $size-s

        +mq-m--mf
          padding: $size-m $size-l

        ::v-deep .skeleton-badge
          margin-bottom: $size-s

    &__section
      padding: $size-s

      +mq-m--mf
        padding: $size-m $size-l

      &__title
        color: $color-ink
        font-weight: 500
        font-size: $font-size-heading-4

    &__question
      padding: $size-xs $size-s

      +mq-m--mf
        padding: $size-m $size-l

      ::v-deep img
        max-width: 98% !important
        height: auto !important

      ::v-deep .skeleton-badge
        margin-bottom: $size-s

    &__options
      padding: $size-xs $size-s
      border-bottom: 1px solid $color-ink-lightest
      margin-bottom: $size-l

      +mq-m--mf
        padding: $size-m $size-l

      ::v-deep .skeleton-badge
        margin-bottom: $size-s

    &__resolution
      padding: $size-xs $size-s

      +mq-m--mf
        padding: $size-m $size-l

      ::v-deep h4
        font-size: $font-size-m
        font-weight: $font-weight-bold
        margin-bottom: $size-xs

      ::v-deep p
        margin-bottom: $size-s
        font-size: $font-size-s !important

    ::v-deep .item-alternative
      border: none
      box-shadow: none
      cursor: default

      .option__letter
        width: $size-m
        height: $size-m
        line-height: $size-m

      &.answer
        .option__letter
          background: $color-success-lightest
          color: $color-success-darker
          border-color: $color-success-lightest

        .item-alternative__body__letter
          background: $color-success-lightest
          color: $color-success-darker
          border-color: $color-success-darker

      &.correct-answer
        background: $color-success-lightest !important
        border: 1px solid $color-success-darker

        .item-alternative__body
          background: $color-success-lightest
          border-radius: $size-xs

          &__letter
            background: $color-success-lightest
            color: $color-success-darker
            border-color: $color-success-darker

      &.wrong-answer
        background: $color-danger-lightest

        .item-alternative__body
          background: $color-danger-lightest
          border: 1px solid $color-danger-darker
          border-radius: $size-xs

          &__letter
            background: $color-danger-lightest
            color: $color-danger-darker
            border-color: $color-danger-darker

