@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"

























































































.info
  display: grid
  grid-gap: 8px
  grid-template-columns: auto 1fr

  &--no-icon
    grid-template-columns: 1fr

  &-left
    display: flex
    align-items: center
    color: $color-ink-lighter

    .left__icon
      width: unset
      height: unset

  &-right
    display: flex
    flex-direction: column
    justify-content: center

    &__label
      font-size: 12px
