@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"




































































































































































































































































































$adaptive-selector-shadow: $shadow-s $color-ink
$color-ice-custom: #F7F7F7
$border-color-custom: #C9CCCF
$box-shadow-custom: rgba(0, 0, 0, 0.25)

.adaptive-selector-dropdown
  position: relative

  +mq-l--mf
    width: auto

  &__name
    flex-grow: 1

  &__handle
    background-color: $color-white
    border: 1px solid $border-color-custom
    box-shadow: 0 0 0 3px $color-ice
    border-radius: 10px
    font-size: $font-size-heading-6-small
    font-weight: $font-weight-semi-bold
    padding: #{$size-m / 2} $size-s
    position: relative
    text-align: left
    width: 100%
    +flex-center-start
    +transition($speed-fast)

    +mq-m--mf
      width: 250px
      z-index: 2

    &:hover:not([disabled])
      background-color: $color-ice
      cursor: pointer

    &:focus
      box-shadow: inset 0 0 0 3px $color-ice
      outline: none

    &__caret
      color: $color-ink-light
      flex-shrink: 1
      margin-left: $size-s
      height: $size-m
      width: $size-m
      margin-left: auto

      +mq-l--mf
        margin-left: $size-l

      ::v-deep .feather__content
        height: 100%
        width: 100%

  &__overlay
    background-color: transparentize($color-ink, 0.5)
    z-index: 11

    +mq-l--mf
      z-index: 2
      display: none

  &__options
    background-color: $color-white
    border-radius: $size-s $size-s 0 0
    bottom: 0
    max-height: 100vh
    left: 0
    position: fixed
    right: 0
    z-index: 12

    +mq-l--mf
      border-radius: $size-s
      bottom: auto
      box-shadow: 0px 5px 14px $box-shadow-custom
      max-height: 40vh
      position: absolute
      overflow: auto
      top: 100%
      z-index: 3
      width: 100%

      &.--left
        left: auto

      &.--right
        right: auto

    &__header
      padding: $size-xs $size-xs $size-xs $size-s
      +flex-center-start

      +mq-l--mf
        display: none

      &__title
        flex-grow: 1

    &__body
      box-shadow: inset 0px 5px 5px -5px $box-shadow-custom
      max-height: calc(100vh - 45px)
      overflow: auto
      padding: $size-s 0 20vh
      width: 100%

      +mq-l--mf
        box-shadow: none
        padding-bottom: $size-s
        overflow: initial

    &__search
      padding: $size-s
      margin-top: -$size-s

      ::v-deep .sas-input__field
        background-color: $color-white

    &__empty
      height: calc(100% - 76px)
      width: 100%
      padding: $size-s
      +flex-column-center

      &__text
        margin-top: $size-s
        text-align: center
        max-width: 240px

        +mq-l--mf
          max-width: 220px

  &__option
    cursor: pointer
    padding: #{$size-m / 2} #{$size-l + $size-m} #{$size-m / 2} $size-s
    position: relative
    +flex-center-start
    +transition($speed-x-fast)
    width: 100%

    &:hover,
    &.--active
      background-color: $color-ice-custom

    &:focus
      outline: none
      box-shadow: inset 0 0 0 3px $color-ice

    &.--disabled
      cursor: not-allowed
      opacity: 0.5

      &:hover
        background-color: transparent

      &:focus
        box-shadow: none

    .feather
      color: $color-ice
      position: absolute
      right: $size-s

  &__option-group
    border-bottom: 1px solid transparentize($color-ink-lightest, 0.5)
    padding-bottom: $size-s
    margin-bottom: $size-s

    &:last-of-type
      border-bottom: none

      +mq-l--mf
        margin-bottom: 0

    &__title
      font-size: $font-size-heading-3-small
      font-weight: $font-weight-semi-bold
      padding: $size-xs $size-s

      +mq-m--mf
        font-size: $font-size-heading-4
