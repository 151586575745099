@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"











































































































































































































$custom-border-color: #0467DB

.selected-box
  border-style: solid
  border-color: $custom-border-color
  border-width: $size-xxs
  transition: 0s

.title-text
  color: $color-ink

.result-exam-card
  outline: none
  cursor: pointer
  margin-right: 12px
  font-size: 13px
  color: $color-ink-light

  +mq-xs--mf
    width: 240px
    margin-left: $size-xl

  +mq-m--mf
    width: 336px
    margin-left: 0

  +mq-l--mf
    width: 100%

  &__header
    margin-bottom: $size-m
    display: grid
    grid-template-columns: 1fr auto

    &--exam
      margin-bottom: 13px

    &--icon
      ::v-deep .sas-badge
        background: $color-primary
        color: $color-white

        i
          mix-blend-mode: normal
          opacity: 1

  &__body
    display: grid
    grid-template-columns: 70% 30%
    margin-bottom: $size-l

  &__footer
    &--info
      display: grid
      grid-template-columns: 1fr auto

  &__olympics-footer
    display: flex
    align-items: center
    justify-content: space-between

    &__info
      &__ranking-number
        font-weight: $font-weight-medium
        color: $color-ink
        font-size: $font-size-m
