@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"





















































































































































































































.scoreboard
  display: grid
  grid-template-columns: 1fr 1fr

  +mq-m
    grid-template-columns: 1fr

  &__content
    box-sizing: border-box
    padding: 32px 40px

    +mq-l
      padding: 32px 24px

    +mq-m
      padding: 32px 48px

    +mq-s
      padding: 24px

  &__title
    color: $color-ink-light

  &__scores
    display: flex
    align-items: center

  &__score
    display: inline-flex
    flex-direction: column

    +mq-s

      h1
        font-size: 28px

      h3
        font-size: 20px

    .left__icon
      width: 36px
      height: 36px

    &:first-child
      width: 200px

      +mq-l
        width: fit-content

      +mq-m
        width: 192px

      +mq-s
        width: 136px

    &:nth-child(2)
      width: 120px

    .info-right__label
      font-size: 16px

      +mq-s
        font-size: 13px

      +mq-xs
        font-size: 10px

    &--skeleton
      display: inline-flex
      flex-direction: column

      &:first-child
        width: 200px

        +mq-l
          width: 152px

        +mq-m
          width: 192px

        +mq-s
          width: 136px

      .skeleton-bone ~ .skeleton-bone
        margin-top: 4px

  .scoreboard__score ~ .scoreboard__score,
  .scoreboard__score--skeleton ~ .scoreboard__score--skeleton
    margin-left: 80px

    +mq-l
      margin-left: 32px

    +mq-xs
      margin-left: 16px

  .scoreboard__content ~ .scoreboard__content
    border-left: 1px solid $color-ink-lightest

    +mq-m
      border-left: unset
      border-top: 1px solid $color-ink-lightest

