@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"





























































































































































.general-result__ranked
  margin-top: 40px
  margin-bottom: 40px

  // Remove wrapper margins
  &-wrapper
    +mq(425px)
      overflow-x: hidden
      max-width: 100%
      .box
        border-radius: 0 !important

  section > h3
    margin-bottom: 24px

    +mq(425px)
      margin-left: 16px

  &__grid
    margin-bottom: 24px
    display: grid
    grid-template-areas: "ranking ranking average"
    gap: 32px 24px

    +mq-l
      grid-gap: 24px 16px
      margin-bottom: 40px

    +mq-m
      grid-template-areas: "ranking ranking ranking" "average average average"

    &.--complex-layout
      grid-template-areas: "ranking ranking ranking" "average average average"

  &__position__grid
    display: grid
    gap: 32px 24px
    grid-template-areas: "positioning positioning"
    grid-template-columns: 1fr 1fr

    &.has-syllabus
      grid-template-areas: "positioning banner"
      grid-template-columns: 1fr 1fr

      +mq-m
        grid-template-areas: "positioning" "banner"
        grid-template-columns: 100%

  .info-card__footer
    .info-2 ~ .info-2
      +mq-s
        margin-left: 24px

  .info-card
    &__header, &__body, &__footer
      +mq-s
        padding: 16px

    .info-card__body .info__icon
      +mq(425px)
        display: none

    // Scores responsive type scale
    h1
      +mq-m
        font-size: 32px
      +mq-s
        font-size: 28px
      +mq-xs
        font-size: 24px

    h3
      +mq-m
        font-size: 18px
      +mq-s
        font-size: 16px

    h4
      +mq-m
        font-size: 16px
      +mq-s
        font-size: 14px
        font-weight: 500

  // Remove info margins on small devices
  .info-card__body > .info-2:not(:first-child)
    +mq-s
      border: none !important
      margin-left: 32px !important
      padding-left: 4px !important

  &__ranking
    grid-area: ranking

  &__average
    grid-area: average

  &__positioning
    grid-area: positioning

    +mq-l
      max-width: 100%

  &__banner
    grid-area: banner

  &__ranking.--complex-layout, &__average.--complex-layout

    .info-card__body
      +mq-l
        display: grid
        grid-template-columns: max-content max-content
        gap: 48px 80px
        justify-content: start !important
      +mq-s
        gap: 32px 48px
      +mq(425px)
        gap: 32px

      .info-2
        +mq-l
          border: none !important
          margin-left: 0 !important
          padding-left: 0 !important

  &__positioning
    .info-card__body
      padding: 0 24px 8px 24px

      +mq-m
        padding: 16px

  // Subjects Data Table
  &__subjects
    +mq-s
      overflow-x: scroll

      .data-table td:first-child,
      .data-table th:first-child
          padding-left: 16px

      .data-table th
        padding: 8px 8px

      .data-table td
        padding: 16px 8px

  &__category-ranking-label
    margin-top: $size-m
