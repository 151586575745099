@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"






































































































.general-result__basic
  margin-top: 40px

  &-hits
    margin-top: 24px

  .study-guide-banner
    margin: 16px 0 48px 0

