@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"

















































.sas-wrapper
  padding-top: $size-l
  display: grid
  padding-bottom: $size-xl
  gap: $size-m
  grid-template-areas: "average" "ranking" "banner"
  grid-template-columns: 100%

  +mq-m--mf
    grid-template-areas: "average average" "ranking banner"
    grid-template-columns: 50% 50%

  +mq-m--mf
    max-width: calc(100% - 80px)

  +mq-l--mf
    max-width: 1032px

  .average
    grid-area: average

  .ranking
    grid-area: ranking

    &.info-card
      box-shadow: 0px 1px 2px rgba(52, 60, 88, 0.4)

    ::v-deep .info-card__header

      .info-card__title
        font-weight: 500
        font-size: $font-size-heading-4
        color: $color-ink

    ::v-deep .info-card__body
      padding: $size-l !important

    ::v-deep .vertical-bar-chart

      &__bars
        grid-template-columns: repeat(5, 28px) !important

        +mq-s--mf
          grid-template-columns: repeat(5, 48px) !important

  .banner
    grid-area: banner

    ::v-deep .questions-banner__box
      background-size: 100% !important

      &__img
        width: 40%

        +mq-m--mf
          width: 100%

