@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"























































































































































































































































































































.study-guide-modal

  ::v-deep .modal
    padding: 0
    box-sizing: border-box
    overflow: hidden
    width: 960px

  ::v-deep .modal__title,
  ::v-deep .modal__footer
    margin: 0
    background: white

  &__body,
  &__footer,
  &__header > h3
    box-sizing: border-box
    padding: 32px 48px

    +mq-m
      padding: 24px 32px

    +mq-s
      padding: 16px

  &__header
    padding: 0
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14)

    h3
      box-sizing: border-box
      padding-bottom: 16px

      +mq-s
        margin-right: 32px

      +mq-xs
        font-size: 20px

  &__body
    height: 60vh
    overflow-y: auto

    &--loading
      display: flex
      align-items: center
      justify-content: center
      height: 100%

    +mq-m
      height: calc(100vh - 160px)

    +mq-s
      padding-bottom: 104px

  &__category
    padding: 0 0 $size-l 0

    +mq-m
      padding: 0 0 $size-s 0

    &-title
      text-align: start
      margin-bottom: $size-s
      min-height: $size-xl
      height: auto
      color: $color-ink

      ::v-deep i.feather
        margin-right: $size-s
        overflow: unset

      &.--enem
        max-width: 95%

        +mq-s
          max-width: 85%

  &__footer
    padding-top: 24px
    padding-bottom: 24px
    width: 100%
    justify-content: space-between

    +mq-s
      padding: 0
      padding-top: 8px
      flex-direction: column
      text-align: center

      h4
        font-size: 14px

    &, &__actions
      display: flex
      align-items: center

    &__actions

      ::v-deep .sas-button ~ .sas-button
        margin-left: $size-s

      +mq-s
        padding: $size-s $size-xs $size-xs $size-xs

  &__table-title
    display: none
    margin-top: $size-xs
    margin-bottom: $size-s

    +mq-s
      display: block

