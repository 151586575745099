@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"























































































































































































.sas-pagination
  margin-top: $size-l
  +flex-space-between

  &__count
    color: $color-ink-light

  &__pages
    +flex-center

  .sas-button
    +space-inset(0 $size-s)

    &.--active
      background: $color-ink
      color: $color-ink

  .sas-button
    margin-right: $size-xxs
